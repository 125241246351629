.header-action .header-btn .btn {
  font-size: 15px !important;
  padding: 15px !important;
  border: 0px solid transparent !important;

  text-transform: capitalize !important;

  border-radius: 5px !important;
}

p {
  text-transform: none !important;
}

.about-content-six p {
  margin-bottom: 10px !important;
}

@media (max-width: 1500px) {
  .breadcrumb-bg {
    padding: 100px 0 100px !important;
  }
}
.breadcrumb-bg {
  background-size: cover;
  background-position: center;
  padding: 100px 0 110px !important;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .element-to-hide {
    display: none;
  }
}
.blurred {
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .about-img-wrap {
    margin-bottom: 0px !important;
    z-index: revert-layer !important;
  }
  .services-item-two {
    position: relative;
    margin-bottom: 0px !important;
    overflow: hidden;
  }
  .about-area-three {
    position: relative;
    padding: 50px 0 !important;
  }
  .request-area.request-bg {
    padding: 50px 0 !important;
  }
  .project-content-top {
    margin-bottom: 0px !important;
  }
  .section-top-content {
    text-align: center;
    margin-bottom: 0px !important;
  }
  .team-skill-wrap {
    margin-top: 15px !important;
  }
  .team-details-info .list-wrap {
    margin-bottom: 0px !important;
  }
  .team-details-info .list-wrap a {
    margin-bottom: 0px !important;
  }
  .about-img-six {
    margin-left: -75px;
    position: relative;
    padding-top: 0px !important;
  }
  .about-img-six {
    margin-bottom: 0px !important;
  }
  .features-area-four {
    padding: 0rem !important;
  }
}
.about-img-wrap-ten {
  padding-left: 15px !important;
}
.about-img-six {
  margin-left: 0px !important;
}
@media (max-width: 767.98px) {
  .view-all-btn.text-end {
    text-align: left !important;
  }
  .services-area-two .section-title-two {
    text-align: left !important;
    margin-bottom: 30px;
  }
  .progress-item .progress-bar span {
    position: static !important;
  }
}
@media (max-width: 767.98px){
.project-bg-two {
    padding: 50px 0 50px !important;
}
.project-bg-two {
  padding: 50px 0 50px !important;
}}
@media screen and (max-width: 768px) {
  .features-item-four {
    height: auto !important; /* Adjust height based on content on smaller screens */
    min-height: 200px !important;
    display: block !important;
   } /* Set a minimum height to prevent content from collapsing */
  .section-title-two .title {
    font-size: 32px;
    text-align-last: left !important;
  }
}
.footer-bottom {
  border-top: 1px solid #253352;
  padding: 20px 0 !important;
}
.footer-top {
  padding: 60px 0 40px !important;
}
@media (max-width: 767.98px) {
  .about-bg {
    padding: 50px 0 50px !important;
  }
}
.phone-numbers-list {
  cursor: pointer;
}
.services-content-two {
  bottom: 0;
  height: 115px;
  left: 0;
  padding: 5px 15px !important;
  position: absolute;
  right: 0;
  transition: all .3s linear;
  z-index: 2;
}

@media (max-width: 767.98px) {
  .project-bg-two {
    padding: 50px 0 50px !important;
  }

  .project-bg-two {
    padding: 50px 0 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .features-item-four {
    height: auto !important;
    /* Adjust height based on content on smaller screens */
    min-height: 200px !important;
    display: block !important;
    /* Set a minimum height to prevent content from collapsing */
  }
}

.team-details-info .list-wrap a {
  display: flex;
  align-items: flex-start;
  font-weight: 600;
  font-size: 18px;
  font-family: var(--tg-heading-font-family);
  margin-bottom: 10px;
  color: black;
}

.services-thumb-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #004799 11.66%, rgba(0, 25, 76, 0) 65.89%) !important;
}
.pointer{
  cursor: pointer;
}

.services-item-two:hover .services-content-two p {
  opacity: 1;

  font-size: 15px !important;
}

.services-item-two:hover .services-content-two {
  bottom: 0px !important;
}

.services-content-two .title {
  margin-bottom: 10px;
  font-size: 16px !important;
  color: var(--tg-white);
}

.services-content-two p {
  margin-bottom: 5px;
  color: var(--tg-lavender);
  opacity: 0;
 
  transition: opacity 0.3s linear;
}


@media (max-width: 1240px) and (min-width: 768px) {
  .services-two-wrapper .services-row .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}


.services-item-two:hover .services-content-two {
  bottom: 0 !important;
  height: 85%;
}

@media (max-width: 425px) {
  .services-item-two:hover .services-content-two p {
    opacity: 1;
    font-size: 11px !important;
  }
}
@media (max-width: 480px) {
  .services-content-two .icon {
    width: 40px;
    height: 40px;
    font-size: 25px;
    margin-bottom: 10px;
}
.services-item-two:hover .services-content-two {
  bottom: 0 !important;
  height: 95%;
}
}

@media (max-width: 991.98px) {
  .section-title-two p {
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .pricing-area-three {
    padding: 50px 0 50px !important;
  }
}

@media (min-width: 1025px) {

  .f-logo img,
  .fw-logo img {
    max-height: 90px;
    height: 100px;
  }
}

.mobile-nav {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 60px 10px 25px;
  font-size: 16px;
  font-weight: 700;
  color: var(--tg-heading-font-color) !important;
  text-transform: capitalize;
  transition: all 500ms ease;
  border: none;
}

.mobile-li {
  position: relative;
  display: block;
  border-top: 1px solid rgb(0 0 0 / 10%);
}

.contact-map iframe {
  width: 100%;
  height: 100%;
  mix-blend-mode: normal !important;
}

@media (max-width: 767.98px) {
  .about-area-nine {
    padding: 50px 0 !important;
  }
}

@media (max-width: 767.98px) {
  .contact-bg {
    padding: 70px 0 !important;
  }

  .about-area-six {
    padding: 50px 0 50px !important;
  }
}


.contact-buttons {
  user-select: none;
  -moz-user-select: none;
  background: rgba(var(--tg-primary-color), 0) none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: var(--tg-black);
  font-weight: 800;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1;
  margin-bottom: 0;
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;


}

.contact-buttons:hover {
  transform: scale(1.05);
  /* Adjust the scale factor as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* Add a subtle shadow on hover */
}

.contact-map {
  width: 100%;
  height: 365px !important;
}

.equal-height-card {
    height: 100%; /* Adjust the height as per your design */
  }
  .header-action .header-btn .btn:hover {
    background: #009cff !important;
    border-color: var(--tg-primary-color);
    color: #ffffff !important;
}
  .card {
    padding: 5px 0px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .error {
    
    color: red;
  }
  .form-control::placeholder {
    color: #999; /* Placeholder text color */
    font-size: 14px !important; /* Placeholder text font size */
    font-weight: 400; /* Placeholder text font weight */
  }
  .form-select {
   /* Placeholder text color */
    font-size: 14px !important; /* Placeholder text font size */
    font-weight: 400 !important; 
    padding: .65rem .75rem !important;
  }
  .form-control{
   padding: .65rem .75rem !important;
  }
  .error-message {
    color: red;
    font-size: 0.8rem;
  }
  .mandatory::after {
    content: "*";
    color: red; /* or any other color you prefer */
  }
  .error-message-name,
  .error-message-email,
  .error-message-phone,
  .error-message-state,
  .error-message-organisation,
  .error-message-message {
    font-size: 0.8rem;
    color: red;
  }
  .thank-you-message {
    padding: 10px;
    margin-top: 10px;
    color: #fff;
    border-radius: 5px;
  }
  
  .thank-you-message.success {
    background-color: rgb(65, 175, 65); /* Background color for success message */
  }
  
  .thank-you-message.error {
    background-color: rgb(216, 72, 72); /* Background color for error message */
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  .main-branch-label {
    font-size: 0.8rem; /* Adjust font size */
    margin-left: 0.5rem; /* Adjust spacing */
    padding: 0.5rem 0.5rem; /* Adjust padding */
    border-radius: 0.25rem; /* Add border radius for rounded corners */
    float: inline-end;
  }
  .team-divider {
    border: 0;
    border-top: 3px solid #ccc; /* Style the divider with a solid line */
    margin: 20px 0; /* Add some spacing above and below the divider */
  }
  .features-item-three:hover .features-content-three .title {
    color: var(--tg-white);
}

.card {
  padding: 5px 0px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.error {

  color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
}

.error-message-name,
.error-message-email,
.error-message-phone,
.error-message-state,
.error-message-organisation,
.error-message-message {
  font-size: 0.8rem;
  color: red;
}

.thank-you-message {
  padding: 10px;
  margin-top: 10px;
  color: #fff;
  border-radius: 5px;
}

.thank-you-message.success {
  background-color: rgb(65, 175, 65);
  /* Background color for success message */
}

.thank-you-message.error {
  background-color: rgb(216, 72, 72);
  /* Background color for error message */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.main-branch-label {
  font-size: 0.8rem;
  /* Adjust font size */
  margin-left: 0.5rem;
  /* Adjust spacing */
  padding: 0.5rem 0.5rem;
  /* Adjust padding */
  border-radius: 0.25rem;
  /* Add border radius for rounded corners */
  float: inline-end;
}

.team-divider {
  border: 0;
  border-top: 3px solid #ccc;
  /* Style the divider with a solid line */
  margin: 20px 0;
  /* Add some spacing above and below the divider */
}

.features-item-three:hover .features-content-three .title {
  color: var(--tg-white);
}

.features-item-three:hover .features-content-three p,
.features-item-three:hover .features-content-three .title {
  color: var(--tg-white);
}

.contact-form form button {
  border: none;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--tg-white);
  text-transform: uppercase;
  background: var(--tg-primary-color);
  border-radius: 5px;
  padding: 14px 20px;
}

.breadcrumb-content .breadcrumb-item {
  color: #ffffff;
}

.features-item {
  border: 0px solid var(--tg-steel-blue);

}

@media (max-width: 767px) {
  .list-wrap.d-inline-flex {
    display: flex !important;
    flex-direction: column
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  p.card-text {
    height: 120px;
}
p.card-text-main {
  height: 80px;
}
}
@media only screen and (min-width: 767px) and (max-width: 992px) {
  p.card-text {
    height: 170px;
}
p.card-text-main {
  height: 95px;
}
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 5);
  backdrop-filter: blur(30px);
  /* Adjust the blur intensity as needed */
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 768px !important;
    margin: 1.75rem auto;
    padding: 20px;
  }
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.services-shape-four {
  position: absolute;
  right: 0;
  z-index: -1;
  opacity: 0.03 !important;
  top: 0% !important;
}

@media (max-width: 767.98px) {
  .about-area-ten {
    padding: 50px 0 !important;
  }

  .features-bg {
    padding: 50px 0 50px !important;
  }

  .choose-area-three {
    padding: 50px 0 !important;
  }

  .team-area-four {
    padding: 50px 0 50px !important;
  }

  .team-details-area {
    padding: 50px 0 !important;
  }

  .services-area-seven {
    padding: 50px 0 50px !important;
  }

  .services-area-three {
    padding: 50px 0 50px !important;
  }
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #292b37;
  background: var(--tg-white);
  cursor: pointer;
  border-radius: 2px;
  transition: all 500ms ease;
  z-index: 5;
}

@media (max-width: 1199.98px) {
  .features-item {
    padding: 25px 25px;
    height: 100%;
  }

  .about-content-six p {
    margin-bottom: 10px !important;
    font-size: 14px !important;
  }

  .features-content-four p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
    font-size: 13px !important;
  }

  .team-details-info .list-wrap a {
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    font-size: 14px !important;
    font-family: var(--tg-heading-font-family);
    margin-bottom: 10px;
    color: black;
  }
}

.form-label {
  font-weight: 900;
  font-size: 17px;
}

.form-control::placeholder {
  font-size: 0.9rem;
  /* Adjust the font size as needed */
}

@media (min-width: 786px) {
  .text-end-1 {
    text-align: right !important;
  }
  /* .services-thumb-two {
    position: relative;
    border-radius: 8px;
    width: 90% !important;
    border: 7px solid var(--tg-white);
    box-shadow: 0px 10px 30px #ced2dd;
    overflow: hidden;
    z-index: 1;
  } */
  .services-content-two p {
    margin-bottom: 5px;
    color: var(--tg-lavender);
    opacity: 0;
    font-size: 14px;
    transition: opacity 0.3s linear;
}
}

@media (max-width: 450px) {
  .modal-font {
    font-size: 0.75rem;
  }
  .modal-dialog-centered {
    align-items: center;
    display: flex;
    min-height: calc(75% - 1rem) !important;
  }
  .contact-margin {
    margin-top: 15px !important;
  }
  .breadcrumb-bg {
    padding: 50px 0 50px !important;
  }
}

.services-area-three {
  background: var(--tg-section-background);
  position: relative;
  z-index: 1;
  padding: 80px 0 80px !important;
}
.project-bg-two {
  padding: 80px 0 80px !important;
}

.pricing-area-three {
  position: relative;
  padding: 80px 0 80px !important;
  overflow: hidden;
  background: #f8f8fa;
  z-index: 1;
}

.choose-area-three {
  background: #00194c;
  background: var(--tg-secondary-color);
  overflow: hidden;
  padding: 80px 0 !important;
  position: relative;
}
.error-area {
  padding: 150px 0 !important;
}